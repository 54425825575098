import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import parse from 'html-react-parser'
import { StaticImageData } from 'next/image'
import { GetImage } from '@/types/global'

interface Button {
  text: string
  href: string
}
export interface ButtonWithClasses {
  button: Button
  classes?: ClassValue
}

export interface ImgWithClasses {
  img: StaticImageData
  classes?: ClassValue
}

/*export function rn(input: string | number): string {
  const numberValue = typeof input === 'string' ? parseFloat(input) : Number(input)

  if (isNaN(numberValue)) {
    return ''
  }

  // Round to two decimal places and use toFixed to ensure it always displays two decimal places.
  const roundedValue = Math.round(numberValue * 100) / 100
  return roundedValue.toFixed(2)
}*/

// Generate tailwind content with parser
export function parsedContent(html: string, classes: ClassValue = ''): { html: string; classes: ClassValue } {
  return { html: (!!html ? parse(html) : '') as string, classes }
}

export function imgWithClasses(img: StaticImageData, classes: ClassValue = ''): ImgWithClasses {
  return { img, classes }
}

export function buttonWithClasses(button: Button, classes: ClassValue = ''): ButtonWithClasses {
  return { button, classes }
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function getImage({ src, alt, className, wrClassName, type = 'local', ...rest }: GetImage) {
  return { src, alt, className, wrClassName, type, ...rest }
}

/*export function rn(stringOrNumber: string | number): number {
  return Math.round(Number(stringOrNumber) * 100) / 100
}*/

// Rounded Number => 1.555 => 1.56 ()
export function rn(input: string | number): string {
  const numberValue = typeof input === 'string' ? parseFloat(input) : Number(input)

  if (isNaN(numberValue)) {
    return ''
  }

  // Round to two decimal places and use toFixed to ensure it always displays two decimal places.
  const roundedValue = Math.round(numberValue * 100) / 100
  return roundedValue.toFixed(2)
}

// 2 Decimal places
export function nf2(input: string | number): string {
  return Number(input).toFixed(2)
}
